<template>
    <BaseContainer>
        <span slot="principal">
            <v-content class="pr-2 pl-2" style="background-color: #555;">
                <v-toolbar class="ma-2" style="border-radius: 30px;">
                    <v-btn icon link to="/">
                        <v-icon>mdi-arrow-left</v-icon>
                    </v-btn>
                    <v-spacer/>
                    <v-toolbar-title v-show="$vuetify.breakpoint.smAndUp">Usuários</v-toolbar-title>
                    <v-spacer/>
                    <v-text-field
                        dense
                        clearable
                        outlined
                        single-line
                        hide-details
                        v-model="search"
                        @keypress.enter="consultar"
                        label="Pesquise.."
                        append-icon="mdi-magnify"
                    />
                    <v-spacer/>
                    <!-- <v-btn color="primary" fab small @click="novo">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn> -->
                </v-toolbar>
                <v-card elevation="2" class="ma-1">
                    <v-card-text>
                        <v-data-table
                            fixed-header
                            :headers="headers"
                            :items="dados"
                            sort-by="id"
                            :mobileBreakpoint="0"
                            :loading="loading"
                            :height="$vuetify.breakpoint.height - 230"
                            :disable-pagination="true"
                            :hide-default-footer="true"
                            :options.sync="options"
                        >
                            <template v-slot:item.empresa_id="{ item }">
                                <span v-if="item.tipo !== 'suporte'">
                                    {{ item.empresa_id }}
                                </span>
                                <v-text-field
                                    v-else
                                    dense
                                    outlined
                                    single-line
                                    hide-details
                                    v-model="item.empresa_id"
                                    @keypress.enter="updateUserEmp(item)"
                                    style="width: 100px;"
                                />
                            </template>
                            <template v-slot:item.created_at="{ item }">
                                {{ formatDate(item.created_at) }}
                            </template>
                            <template v-slot:item.permissions="{ item }">
                                <v-btn
                                    v-if="['suporte', 'diretor', 'gestor'].includes(item.tipo)"
                                    @click="onPermissionsClick(item)"
                                    icon
                                >
                                    <v-icon>mdi-shield-account-outline</v-icon>
                                </v-btn>
                            </template>
                        </v-data-table>
                        <Paginate v-model="pagination" @input="consultar"/>
                    </v-card-text>
                </v-card>
            </v-content>

            <PermissionsDialog
                v-if="permissionsDialogVisible && checkPermission('zapercontrol_usuarios_permissoes_consultar')"
                v-model="userId"
                @close="permissionsDialogVisible = false"
            />
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';
import Paginate from '@/components/Paginate';
import PermissionsDialog from './PermissionDialog';

export default {
    name: 'Usuarios',

    components: {
        BaseContainer,
        Paginate,
        PermissionsDialog,
    },

    data: () => ({
        search: '',
        permissionsDialogVisible: false,
        userId: null,
        loading: false,
        dados: [],
        headers: [
            { text: 'ID', value: 'id' },
            { text: 'Nome', value: 'name' },
            { text: 'E-mail', value: 'email' },
            { text: 'Empresa ID', value: 'empresa_id' },
            { text: 'Criado em', value: 'created_at' },
            { text: 'Permissões', value: 'permissions' },
        ],
        pagination: {
            current_page: 1,
            last_page: 1,
            total: 0,
        },
        options: {},
    }),

    mounted() {
        this.consultar();
    },

    watch: {
        options: {
            async handler() {
                this.consultar();
            },
            deep: true,
        },
    },

    methods: {
        consultar() {
            if (!this.checkPermission('zapercontrol_usuarios_consultar')) {
                alert('Usuário sem permissão');
                return;
            }

            this.loading = true;
            this.dados = [];
            const sortBy = this.options.sortBy.length ? `&sortField=${this.options.sortBy[0]}` : '';
            const sortOrder = this.options.sortDesc.length ? `&sortOrder=${this.options.sortDesc[0] ? 1 : -1}` : '';
            const params = `page=${this.pagination.current_page}&q=${this.search || ''}${sortBy}${sortOrder}`;

            this.$http.post(`usuarios/get-all?${params}`).then(resp => {
                this.pagination = resp.data.meta;
                this.dados = resp.data.data;
                this.loading = false;
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        updateUserEmp({ empresa_id, id: user_id}) {
            this.$http.post('usuarios/update-emp', { empresa_id, user_id}).then(() => {
                alert('Empresa atualizada com sucesso');
            })
            .catch(() => {
                alert('Não foi possivel concluir a operação');
                this.loading = false;
            });
        },

        onPermissionsClick(user) {
            // if (!this.checkPermission('zapercontrol_usuarios_permissoes_consultar')) {
            //     alert('Usuário sem permissão');
            //     return;
            // }
            this.userId = user.id;
            this.permissionsDialogVisible = true;
        },
    },
}
</script>
